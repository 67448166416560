var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("vx-card", { staticClass: "mb-4" }, [
        _c("div", { staticClass: "flex flex-col gap-4" }, [
          _c("div", { staticClass: "flex flex-col gap-2" }, [
            _c(
              "div",
              { staticClass: "flex w-full grid-cols-2 gap-2 align-baseline" },
              [
                _c(
                  "div",
                  { staticClass: "w-1/3" },
                  [
                    _c("vs-input", {
                      directives: [
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: "required|numeric|max:16",
                          expression: "'required|numeric|max:16'",
                        },
                      ],
                      staticClass: "w-full required",
                      attrs: {
                        id: "data_access_owner_id",
                        label: _vm.$t("data_access.owner_id"),
                      },
                      model: {
                        value: _vm.model.owner_id,
                        callback: function ($$v) {
                          _vm.$set(_vm.model, "owner_id", $$v)
                        },
                        expression: "model.owner_id",
                      },
                    }),
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.errors.has("owner_id"),
                            expression: "errors.has('owner_id')",
                          },
                        ],
                        staticClass: "text-danger text-sm",
                      },
                      [_vm._v(_vm._s(_vm.errors.first("owner_id")))]
                    ),
                  ],
                  1
                ),
                _c("vs-input", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "required|max:191",
                      expression: "'required|max:191'",
                    },
                  ],
                  staticClass: "w-full required",
                  attrs: {
                    id: "data_access_owner_type",
                    label: _vm.$t("data_access.owner_type"),
                  },
                  model: {
                    value: _vm.model.owner_type,
                    callback: function ($$v) {
                      _vm.$set(_vm.model, "owner_type", $$v)
                    },
                    expression: "model.owner_type",
                  },
                }),
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.errors.has("owner_type"),
                        expression: "errors.has('owner_type')",
                      },
                    ],
                    staticClass: "text-danger text-sm",
                  },
                  [_vm._v(_vm._s(_vm.errors.first("owner_type")))]
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "flex w-full grid-cols-2 gap-2 align-baseline" },
              [
                _c(
                  "div",
                  { staticClass: "w-1/3" },
                  [
                    _c("vs-input", {
                      directives: [
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: "required|numeric|max:16",
                          expression: "'required|numeric|max:16'",
                        },
                      ],
                      staticClass: "w-full required",
                      attrs: {
                        id: "data_access_model_id",
                        label: _vm.$t("data_access.model_id"),
                      },
                      model: {
                        value: _vm.model.model_id,
                        callback: function ($$v) {
                          _vm.$set(_vm.model, "model_id", $$v)
                        },
                        expression: "model.model_id",
                      },
                    }),
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.errors.has("model_id"),
                            expression: "errors.has('model_id')",
                          },
                        ],
                        staticClass: "text-danger text-sm",
                      },
                      [_vm._v(_vm._s(_vm.errors.first("model_id")))]
                    ),
                  ],
                  1
                ),
                _c("vs-input", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "required|max:191",
                      expression: "'required|max:191'",
                    },
                  ],
                  staticClass: "w-full required",
                  attrs: {
                    id: "data_access_model_type",
                    label: _vm.$t("data_access.model_type"),
                  },
                  model: {
                    value: _vm.model.model_type,
                    callback: function ($$v) {
                      _vm.$set(_vm.model, "model_type", $$v)
                    },
                    expression: "model.model_type",
                  },
                }),
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.errors.has("model_type"),
                        expression: "errors.has('model_type')",
                      },
                    ],
                    staticClass: "text-danger text-sm",
                  },
                  [_vm._v(_vm._s(_vm.errors.first("model_type")))]
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "flex w-full grid-cols-2 gap-2 align-baseline" },
              [
                _c(
                  "div",
                  { staticClass: "w-1/3" },
                  [
                    _c("vs-input", {
                      directives: [
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: "max:16",
                          expression: "'max:16'",
                        },
                      ],
                      staticClass: "w-full",
                      attrs: {
                        id: "data_access_level",
                        label: _vm.$t("data_access.level"),
                      },
                      model: {
                        value: _vm.model.level,
                        callback: function ($$v) {
                          _vm.$set(_vm.model, "level", $$v)
                        },
                        expression: "model.level",
                      },
                    }),
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.errors.has("level"),
                            expression: "errors.has('level')",
                          },
                        ],
                        staticClass: "text-danger text-sm",
                      },
                      [_vm._v(_vm._s(_vm.errors.first("level")))]
                    ),
                  ],
                  1
                ),
              ]
            ),
          ]),
          _c(
            "div",
            { staticClass: "flex w-full align-baseline" },
            [
              _c("select-suggestion", {
                ref: "select_institution",
                class: _vm.institution ? "flex-grow" : "flex-grow",
                attrs: {
                  max: 20,
                  column: "name",
                  model: "Institution",
                  label: _vm.$t("fields.institution"),
                  appendClearOption: true,
                  placeholderText: "Digite o nome da instituição",
                },
                model: {
                  value: _vm.institution,
                  callback: function ($$v) {
                    _vm.institution = $$v
                  },
                  expression: "institution",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "w-full flex gap-2 justify-end" },
            [
              !_vm.isEdit()
                ? _c(
                    "vs-button",
                    {
                      directives: [
                        {
                          name: "data_access",
                          rawName: "v-data_access",
                          value: "data_access.create",
                          expression: "'data_access.create'",
                        },
                      ],
                      attrs: { disabled: !_vm.validateForm },
                      on: { click: _vm.createOrUpdate },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("action.save")) + " ")]
                  )
                : _vm._e(),
              _vm.isEdit()
                ? _c(
                    "vs-button",
                    {
                      directives: [
                        {
                          name: "data_access",
                          rawName: "v-data_access",
                          value: "data_access.edit",
                          expression: "'data_access.edit'",
                        },
                      ],
                      attrs: { disabled: !_vm.validateForm },
                      on: { click: _vm.createOrUpdate },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("action.save")) + " ")]
                  )
                : _vm._e(),
              _c(
                "vs-button",
                { attrs: { type: "border" }, on: { click: _vm.cancel } },
                [_vm._v(" " + _vm._s(_vm.$t("common.back")) + " ")]
              ),
            ],
            1
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }